import React, { Fragment, useState, useContext } from 'react';
import OptionsList from './optionsList/OptionsList';

import MassinsApiContext from '../../../store/MassinsApi';

function OptionsContainer() {

    // Global Map Response Variable
    const { macValue__ctx } = useContext(MassinsApiContext);
    const [macValue, setMacValue] = macValue__ctx;

    const options1 = [
        ['Building', macValue["data"]["singleQuote__result"]["mostCompetetiveOption"]["coverages"]["coverageA"], 'currency'],
        ['Other Structures', macValue["data"]["singleQuote__result"]["mostCompetetiveOption"]["coverages"]["coverageB"], 'currency'],
        ['Contents', macValue["data"]["singleQuote__result"]["mostCompetetiveOption"]["coverages"]["coverageC"], 'currency'],
        ['Loss of Use', macValue["data"]["singleQuote__result"]["mostCompetetiveOption"]["coverages"]["coverageD"], 'currency'],
        ['Medical Payments', macValue["data"]["singleQuote__result"]["mostCompetetiveOption"]["coverages"]["coverageE"], 'currency'],
        ['Personal Liability', macValue["data"]["singleQuote__result"]["mostCompetetiveOption"]["coverages"]["coverageF"], 'currency']
    ];
    const options2 = [
        ['Deductible', macValue["data"]["singleQuote__result"]["mostCompetetiveOption"]["coverages"]["aopDeductible"], 'currency', null, [500, 1000, 1500, 2000, 2500, 5000, 10000]],
        ['Wind Deductible', macValue["data"]["singleQuote__result"]["mostCompetetiveOption"]["coverages"]["windDeductible"], 'text', 450000, [
            [null, 1, 'percent'], [null, 2, 'percent'], [null, 5, 'percent'], [null, 1000, 'currency'], [null, 1500, 'currency'], [null, 2000, 'currency'], [null, 2500, 'currency'], [null, 5000, 'currency'], [null, 10000, 'currency'], [null, 'None', 'text']
        ]]
    ];
    const options3 = [
        ['Occupancy', macValue["data"]["singleQuote__result"]["quoteRequest"]["dwelling"]["occupancy"], 'text', null, [
            [null, 'Primary', 'text'], [null, 'Secondary', 'text'], [null, 'Renter', 'text'], [null, 'Tenant', 'text']
        ]],
        ['Families', macValue["data"]["singleQuote__result"]["quoteRequest"]["dwelling"]["families"], 'int', null, [
            [null, '1', 'text'], [null, '2', 'text'], [null, '3', 'text'], [null, '4+', 'text']
        ]],
        ['Loss Free Years', macValue["data"]["singleQuote__result"]["quoteRequest"]["misc"]["lossFreeYears"], 'text', null, [
            [null, '1', 'text'], [null, '2', 'text'], [null, '3', 'text'], [null, '4', 'text'], [null, '5', 'text'], [null, '6', 'text'], [null, '7', 'text'], [null, '8', 'text'], [null, '9', 'text'], [null, '10+', 'text']
        ]],
        ['Distance to Fire Hydrant (ft)', '800', 'text']
    ];

    // State Management
    const [editState, setEditState] = useState(false);

    return (
        <Fragment>
            <OptionsList options={options1} title={'COVERAGES'} showEdit='true' toggleEdit={editState => setEditState(editState)} editState={editState} />
            <OptionsList options={options2} title={'DEDUCTIBLES'} toggleEdit={editState => setEditState(editState)} editState={editState} />
            <OptionsList options={options3} title={'RATING FACTORS'} toggleEdit={editState => setEditState(editState)} editState={editState} />
        </Fragment>
    );
}

export default OptionsContainer;