import React, { Fragment } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStopwatch, faGlobeAmericas, faSearchLocation, faFileInvoice } from '@fortawesome/free-solid-svg-icons';


import classes from './footer.module.css';


function Footer() {

    // Global Map Response Variable
    // const { macValue__ctx, searchState__ctx } = useContext(MassinsApiContext);
    // const [macValue, setMacValue] = macValue__ctx;

    // const [searchState, setSearchState] = searchState__ctx;

    return (
        <div className={classes.masterContainer}>
            <span>Terms</span>
            <span>Contact</span>
            <span>About the Developer</span>
            <span>&copy; Copyright {new Date().getFullYear()}, massins.com |  Copyright {new Date().getFullYear()}, MassFlood</span>
        </div>
    );
}

export default Footer;



