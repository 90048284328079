import React, { Fragment, useState } from 'react';
import Option from './option/Option';

import classes from './optionsList.module.css';


function OptionsList(props) {
    // console.log("OptionsList props:" . props);
    const options = props.options;

    function toggleEditHandler() {
        props.toggleEdit(!props.editState);
        console.log("toggleEditHandler: " + props.editState);
    }

    // Edit Button
    const editButton = props.showEdit ? <h3 className={`editButton ${props.editState ? 'enableEdit' : ''}`} onClick={toggleEditHandler}>Edit</h3> : null;

    return (
        <Fragment>
            < div className={classes.titleContainer} >
                <h4>{props.title}</h4>
                {/* {editButton} */}
            </div >
            <div className={classes.optionsList}>
                {options.map((option, index) => (
                    // <div key={index}>
                    <Fragment key={index}>
                        {index !== 0 && <hr />}
                        <Option option={option} key={`${option[0]}__${index}`} editToggle={props.editState} />
                    </Fragment>
                    // </div>
                ))
                }
            </div >
        </Fragment >
    );
}

export default OptionsList;