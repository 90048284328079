import React, { Fragment, useContext } from 'react';
import QuoteUI from '../components/stages/quoteDetails/QuoteDetails'
import PropertySearch from '../components/stages/propertySearch/PropertySearch';
import MassinsApiContext from '../store/MassinsApi';

// import classes from './singleQuote.module.css';


function SingleQuote() {

    // Global Map Response Variable
    const { macValue__ctx, searchState__ctx } = useContext(MassinsApiContext);
    const [macValue, setMacValue] = macValue__ctx;

    const [searchState, setSearchState] = searchState__ctx;

    return (
        <Fragment>
            <PropertySearch />
            {(searchState[2] == 'confirmed') ? <QuoteUI></QuoteUI> : ''}
            {/* <QuoteDetails /> */}
        </Fragment>
    );
}

export default SingleQuote;



