import React from 'react';
import { MassinsApiContextProvider } from './store/MassinsApi';
import SingleQuote from './pages/SingleQuote';
import ProgramInformation from './pages/ProgramInformation';
import Development from './pages/Development';
import Footer from './pages/Footer';
import Modal from './components/ui/modals/Modal';

import { ModalStateContextProvider } from './store/ModalState';

function App() {
  return (
    <ModalStateContextProvider>
      <MassinsApiContextProvider>
        <Development />
        <SingleQuote />
        <ProgramInformation />
        <Footer />
        <Modal />
      </MassinsApiContextProvider>
    </ModalStateContextProvider>
  );
}

export default App;
