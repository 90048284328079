import React, { useEffect, useContext } from 'react';
import OptionsList from '../../ui/optionsContainer/optionsList/OptionsList';
import OptionsContainer from '../../ui/optionsContainer/OptionsContainer';

import scrollIntoView from 'smooth-scroll-into-view-if-needed'

import MassinsApiContext from '../../../store/MassinsApi';
import ModalStateContext from '../../../store/ModalState';

import classes from './quoteDetails.module.css';
import './quoteDetails.css';

// AJAX
// const axios = require('axios').default;



// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
//   formatter.format(2500); /* $2,500.00 */

function QuoteDetails() {

    // Global Map Response Variable
    const { macValue__ctx } = useContext(MassinsApiContext);
    const [macValue, setMacValue] = macValue__ctx;

    const { modalState__ctx } = useContext(ModalStateContext);
    const [modalState, setModalState] = modalState__ctx;

    const handelModalState = (modal) => {
        setModalState({
            modalContent: modal
        });
    }

    useEffect(() => {
        // document.getElementById('quoteDetails').scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        const node = document.getElementById('quoteDetails__container');

        let scrollToLocation = 'center';
        if (window.innerHeight < 800) {
            scrollToLocation = 'nearest';
        }

        scrollIntoView(node, {
            scrollMode: 'if-needed',
            block: scrollToLocation,
            inline: scrollToLocation,
        });
    },
        // array of variables that can trigger an update if they change. Pass an
        // an empty array if you just want to run it once after component mounted. 
        []);
    return (
        <div id='quoteDetails__container' className='section'>
            <div id='quoteDetails' className={`${classes.center} ${classes.quoteDetails}`}>
                <h2>Home Insurance Quote</h2>
                <h6>{!macValue ? null : macValue["data"]["singleQuote__result"]["quoteRequest"]["propertyAddress"].join(", ")}</h6>


                {/* TODO REWIRE */}
                <h1>{!macValue ? null : formatter.format(macValue["data"]["singleQuote__result"]["mostCompetetiveOption"]["quoteResult"])}</h1>



                <button className='primaryButton' onClick={() => handelModalState('requestInformation')}>Request Information</button>
                <OptionsContainer />
                <h5>Quote generated using known, calculated, presumed, and/or user entered data. Quote is non-binding and subject to insurance agent review.</h5>
            </div>
            <hr className={classes.hrBreak} />
        </div>
    );
}




export default QuoteDetails;