import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faIdCardAlt, faBell } from '@fortawesome/free-solid-svg-icons';

import ModalStateContext from '../../../../store/ModalState';
import MassinsApiContext from '../../../../store/MassinsApi';

import classes from './requestInformation.module.css';

// AJAX
function sendRequestForInformation(props) {
    // Set Searching
    // console.log("quoteDetails: ", props.quoteDetails);
    // console.log("formData: ", props.formData);
    // AJAX
    axios({
        method: 'post',
        // url: 'http://www.testola.com/api/handelRequestForMoreInformation.php',
        url: props.domain + '/api/handelRequestForMoreInformation.php',
        timeout: 5000,  // 5 seconds timeout
        data: {
            quoteDetails: props.quoteDetails,
            formData: props.formData
            // quoteDetails: props.quoteDetails,
            // formData: props.formData
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            // Set the (global) context value with response
            if (response) {
                // console.log(response);
                console.log(response["data"]);


                // console.log(JSON.parse(response.data.researchData));
                // setMacValue(response);
            }
            // // Set Searching
            // setSearching(false);

            // // Re-enable map
            // setDisableMap(false);
            // setConfirmLocationButton__state(true);
        })
        .catch(error => console.error('timeout exceeded'));
}

function RequestInformation__MODAL() {

    // Global Map Response Variable
    const { macValue__ctx, domain__ctx } = useContext(MassinsApiContext);
    const [macValue, setMacValue] = macValue__ctx;

    // Global Modal State Varible
    const { modalState__ctx } = useContext(ModalStateContext);
    const [modalState, setModalState] = modalState__ctx;

    // Global Domain Variable
    const [domain, setDomain] = domain__ctx;

    //
    const [requestSendSuccess, setRequestSendSuccess] = useState(false);

    // Form and Form Element States
    const [enteredName, setEnteredName] = useState(['', 0]);
    const [enteredEmail, setEnteredEmail] = useState(['', 0]);
    const [enteredNotes, setEnteredNotes] = useState(['', 0]);
    const [formIsValid, setFormIsValid] = useState(false);

    // Form and Form Element Handelers
    const nameChangeHandler = (event) => {
        setEnteredName([event.target.value, 0]);
    }
    const emailChangeHandler = (event) => {
        setEnteredEmail([event.target.value, 0]);
    }
    const notesChangeHandler = (event) => {
        setEnteredNotes([event.target.value, 0])
    }

    // Submit Button
    const submitRequestHandler = (event) => {
        event.preventDefault();
        sendRequestForInformation({ 'quoteDetails': macValue['data']['singleQuote__result'], 'formData': { 'enteredName': enteredName[0], 'enteredEmail': enteredEmail[0], 'enteredNotes': enteredNotes[0] }, 'domain': domain });


        setTimeout(function () {
            setRequestSendSuccess(true);
        }, 500);

        setTimeout(function () {
            document.getElementById('modalOverlay').classList.remove('fadeIn');
            document.getElementById('modalBackdrop').classList.remove('fadeIn');
            setTimeout(function () {
                setModalState(null);
                console.log("Modal closed!");
            }, 800);
        }, 4000);
        // console.log('Submitted Form!');
    }

    // Check validity of form
    useEffect(() => {
        const identifier = setTimeout(() => {
            // console.log('Chekcing form validity');
            setFormIsValid(
                enteredName[0].trim().length > 0 && enteredEmail[0].includes('@' && '.')
            );
        }, 300);

        return () => {
            // console.log('Cleanup');
            // console.log('formIsValid: ', formIsValid);
            clearTimeout(identifier);
        };
    }, [enteredName, enteredEmail]);

    const preSendHTML =
        <div className={`fadeIn ${classes.preSendHTML}`}>
            <h2 className={classes.title}>Request Information</h2>
            <div className={classes.addressContainer}>
                <h4 className={classes.address}>{`${!macValue ? null : macValue["data"]["singleQuote__result"]["quoteRequest"]["propertyAddress"][0]}, ${macValue["data"]["singleQuote__result"]["quoteRequest"]["propertyAddress"][1]}`}</h4>
                {/* <h3 className={classes.address}>{!macValue ? null : macValue["data"]["singleQuote__result"]["quoteRequest"]["propertyAddress"][1]}</h3> */}
            </div>
            <div>
                {/* <FontAwesomeIcon icon={faPaperclip} /> */}
                <p className={classes.explination}>Your quote will be attached with your request for more information. An insurance agent will review your request and be in touch shortly.</p>
            </div>
            <div className={classes.contactFormContainer}>
                <form className={classes.contactFormOptionsList} action="/action_page.php">
                    <div className={classes.option}>
                        <label>Name</label>
                        <input type="text" id="enteredName" name="name" placeholder="Your Name" value={enteredName[0]} onChange={nameChangeHandler} />
                    </div>
                    <hr />
                    <div className={classes.option}>
                        <label>Email</label>
                        <input type="email" id="enteredEmail" name="email" placeholder="Email Address" value={enteredEmail[0]} onChange={emailChangeHandler} />
                    </div>
                    <hr />

                    <div className={[classes.option, classes.notes].join(' ')}>
                        <label>Notes</label>
                        <textarea id="enteredNotes " rows="4" cols="50" name="notes" form="contactForm" placeholder="Notes" value={enteredNotes[0]} onChange={notesChangeHandler} />
                    </div>
                </form>
            </div >
            <h5>Quote generated using known, calculated, presumed, and/or user entered data. Quote is non-binding and subject to insurance agent review.</h5>
            <button disabled={!formIsValid} className={`${classes.submitButton} ${formIsValid ? 'primaryButton' : ''}`} onClick={submitRequestHandler}>Send</button>
        </div>

    const postSendHTML =
        <div className='fadeIn' >
            <h2 className={classes.title}>Your Request Has Been Sent</h2>
        </div >


    return (
        <React.Fragment>
            {!requestSendSuccess ? preSendHTML : postSendHTML}
        </React.Fragment>
    );
}

export default RequestInformation__MODAL;