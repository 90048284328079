import React, { useContext, Fragment } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStopwatch, faGlobeAmericas, faSearchLocation, faFileInvoice } from '@fortawesome/free-solid-svg-icons';


import classes from './development.module.css';

import MassinsApiContext from '../store/MassinsApi';

function Development() {

    // Global Map Response Variable
    const { domain__ctx } = useContext(MassinsApiContext);
    const [domain, setDomain] = domain__ctx;

    // const [searchState, setSearchState] = searchState__ctx;

    return (
        <Fragment>
            {(domain == '') ? null : <div className={classes.developmentText}>{`⚠️ _UNDER DEVELOPMENT_⚠️`}</div>}
        </Fragment>
    );
}

export default Development;



