import React, { useState } from 'react';

const MassinsApiContext = React.createContext({
    macValue: null,
    prettyAddress: null,
    searching: false,
    reQueryMap__ctx: false
});

// export default MassinsApiContext;

// DYNAMIC - NOT BEING USED
export const MassinsApiContextProvider = (props) => {

    const [macValue, setMacValue] = useState(null);
    const [searchState, setSearchState] = useState('initial')


    const [searching, setSearching] = useState(false);
    const [reQueryMap__state, setReQueryMap__state] = useState(false);
    const [confirmLocation__state, setConfirmLocation__state] = useState(false);
    const [confirmLocationButton__state, setConfirmLocationButton__state] = useState(false);

    const [domain, setDomain] = useState(''); // or testola
    // const [domain, setDomain] = useState('https://www.testola.com'); // or massins

    return <MassinsApiContext.Provider value={{ macValue__ctx: [macValue, setMacValue], searching__ctx: [searching, setSearching], reQueryMap__ctx: [reQueryMap__state, setReQueryMap__state], confirmLocation__ctx: [confirmLocation__state, setConfirmLocation__state], confirmLocationButton__ctx: [confirmLocationButton__state, setConfirmLocationButton__state], searchState__ctx: [searchState, setSearchState], domain__ctx: [domain, setDomain] }}> {props.children}</MassinsApiContext.Provider >
}
export default MassinsApiContext;
