import React, { useState } from 'react';

const ModalStateContext = React.createContext({
    modalState: false
});

// export default ModalStateContext;

// DYNAMIC - NOT BEING USED
export const ModalStateContextProvider = (props) => {

    const [modalState, setModalState] = useState(null);

    return <ModalStateContext.Provider value={{ modalState__ctx: [modalState, setModalState] }}> {props.children}</ModalStateContext.Provider >
}
export default ModalStateContext;
