import React, { useRef, useState, useEffect, useContext, Fragment } from "react";

import MapView from "@arcgis/core/views/MapView";
import Map from '@arcgis/core/Map';
import Search from '@arcgis/core/widgets/Search';
import ModalStateContext from '../../store/ModalState';
import Loader from '../ui/loader/Loader';

import scrollIntoView from 'scroll-into-view-if-needed'

import MassinsApiContext from "../../store/MassinsApi";

import './mapView.css'


function submitSearch() {
    document.getElementsByClassName('esri-search__submit-button')[0].click();
}


function MapApp() {

    const { modalState__ctx } = useContext(ModalStateContext);
    const [modalState, setModalState] = modalState__ctx;

    // const handelModalState = (modal) => {
    //     setModalState({
    //         modalContent: modal
    //     });
    // }

    // Map Opacity State
    const [viewDivOpacity, setViewDivOpacity] = useState(false);
    const [disableMap, setDisableMap] = useState(true);

    // Global Map Response Variable
    const { macValue__ctx, searchState__ctx, searching__ctx, reQueryMap__ctx, confirmLocation__ctx, confirmLocationButton__ctx, domain__ctx } = useContext(MassinsApiContext);
    //
    const [macValue, setMacValue] = macValue__ctx;
    const [searchState, setSearchState] = searchState__ctx;
    const [domain, setDomain] = domain__ctx;

    // Initial
    // Searching
    // Complete 
    //  - valid
    //  - ⚠️ Query returned no parcel.
    //  - ⚠️ Property not zoned as residential.
    //  - ⚠️ Property Not Currently Supported.

    // const [searching, setSearching] = searching__ctx;
    // const [reQueryMap__state, setReQueryMap__state] = reQueryMap__ctx;
    // const [confirmLocation__state, setConfirmLocation__state] = confirmLocation__ctx;
    // const [confirmLocationButton__state, setConfirmLocationButton__state] = confirmLocationButton__ctx;





    // State & Element | Search Box
    // const [searchUI__state, setSearchUI__state] = useState(true);

    // AJAX
    const axios = require('axios').default;
    function apiRouter(props) {
        // setSearching(true);
        // setSearchUI__state(false);
        // setReQueryMap__state(false);
        // setConfirmLocationButton__state(false);

        // AJAX
        axios({
            method: 'post',
            url: props.domain + '/api/api__router.php',
            timeout: 8000,  // 5 seconds timeout
            data: {
                coordinates: props.coordinates,
                opperation: 'singleQuote'
            },
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                // Set the (global) context value with response
                // console.log("API Router Response: ", response["data"]["singleQuote__result"]);

                // Set Searching
                setSearchState(['complete']);

                // Re-enable map
                // setDisableMap(false);


                // Set the response
                setMacValue(response);

                // Handle the response
                switch (response["data"]["singleQuote__result"]) {
                    case "⚠️ Query returned no parcel.":
                        console.log(response["data"]["singleQuote__result"]);
                        setModalState({ modalContent: 'queryError', details: 'Unable To Find That Address' });
                        reQueryMap();
                        break;
                    case "⚠️ Property not zoned as residential":
                        console.log(response["data"]["singleQuote__result"]);
                        setModalState({ modalContent: 'queryError', details: 'Records Indicate This Address Is Not Zoned As Residential' });
                        reQueryMap();
                        break;
                    case "⚠️ Property Not Currently Supported.":
                        console.log(response["data"]["singleQuote__result"]);
                        setModalState({ modalContent: 'queryError', details: 'Propery Not Currently Supported' });
                        reQueryMap();
                        break;
                    default:
                        console.log(response["data"]["singleQuote__result"]);
                        console.log("searchState: ['complete', 'valid', 'notConfirmed']",);
                        setSearchState(['complete', 'valid', 'notConfirmed']);
                        break;
                }
            })
            .catch(error => console.error(error));
    }

    const mapDiv = useRef(null);
    useEffect(() => {
        if (mapDiv.current) {
            // Initialize application
            const map = new Map({
                //basemap: "dark-gray-vector"
                basemap: "gray-vector"
            });

            const view = new MapView({
                container: mapDiv.current,
                map: map,
                zoom: 8, // Sets zoom level based on level of detail (LOD)
                center: [-70.811736, 41.658652], // Sets center point of view using longitude,latitude
                // FUTURE Media Query (zoom widget)
                ui: { // Delete the Zoom widget from the view's default UI components by excluding the zoom string from the list of default UI widgets 
                    components: ["attribution"]
                    // components: []
                }
            });

            // Define Search Widget
            const searchWidget = new Search({
                view: view,
                autoSelect: true,
                popupEnabled: false,
                suggestionsEnabled: true,
                locationEnabled: false,
                container: "searchContainer", // ID of conatiner for widget
                filter: {
                    // where: "searchTerm = 'Massachusetts'"
                }
            });

            searchWidget.goToOverride = function (view, goToParams) {
                goToParams.options.duration = 2000;
                return view.goTo(goToParams.target, goToParams.options);
            };

            // On Search Start
            searchWidget.on('search-start', function () {
                // Set searchState to searching
                console.log("Starting Search!");
                setSearchState('searching');

                // Remove Focus (hide mobile keyboard that is probably still open)
                document.activeElement.blur();

                // Scroll to the top (helps to mitigate issue where mobile browser will scroll down when typing in the inout field)
                const node = document.getElementById('root').children[0];
                scrollIntoView(node, {
                    scrollMode: 'if-needed',
                    block: 'start',
                    inline: 'start',
                });

                document.getElementById('mapOverlay').classList.remove('fadeIn'); // Fade Submit Button
            });

            // On Search Complete -> return the coordinates
            searchWidget.on('search-complete', function (result) {
                console.group("Search coordinates");
                // Result Latitude and Longitude
                const resultLatitude = result.results[0].results[0].feature.geometry.latitude;
                const resultLongitude = result.results[0].results[0].feature.geometry.longitude;
                // Combine in array
                const resultCoordinates = [resultLatitude, resultLongitude];
                console.log("%cresultCoordinates: ", "color:orange", resultCoordinates);
                console.groupEnd();

                // MassIns API
                apiRouter({ "coordinates": resultCoordinates, 'domain': domain }); // searchState is set to complete here

                // // Show Map
                // setViewDivOpacity(true);
            });

            // Wait for search to load, then focus()
            const observer = new MutationObserver((mutations, obs) => {
                const element = searchWidget.container;
                if (element.children[0]) {
                    if (element.children[0].children[0]) {
                        if (element.children[0].children[0].children[0]) {
                            if (element.children[0].children[0].children[0].children[0]) {
                                element.children[0].children[0].children[0].children[0].focus();
                                // console.log(element);
                                obs.disconnect();
                                return;
                            }
                        }
                    }
                }
            });
            observer.observe(document, {
                childList: true,
                subtree: true
            });
        }
    }, []);






    // Yes (confirm location)
    function showQuoteDetails() {
        setSearchState(['complete', 'valid', 'confirmed']);

        // Scroll then change state :)
        setTimeout(function () {
            setSearchState(['complete', 'valid', 'confirmed', 'proposed']);
        }, 500)
    }


    function reQueryMap() {
        setTimeout(function () {
            document.getElementById('mapOverlay').classList.add('fadeIn'); // Fade Submit Button
            setSearchState('requote');
            document.getElementById('searchContainer').children[0].children[0].children[0].children[0].focus();
            document.getElementById('searchContainer').children[0].children[0].children[0].children[0].value = null;
        }, 500);
    }

    //  Confrim Location Buttons
    const confirmLocation =
        <Fragment>
            <div id='confirmLocation__container' className='fadeIn'><button className='primaryButton' onClick={showQuoteDetails}>Yes</button><button className='primaryButton' onClick={reQueryMap}>No</button></div>
        </Fragment >


    // State & Element | SearchUI Elements
    const searchUI__elements =
        <div className={'searchUI_elements'}>
            <button onClick={submitSearch} className='primaryButton'>Submit</button>
            <div className='searchHelperInformation'>
                {searchState[2] == 'requote' ?
                    null
                    :
                    <h4>
                        Get an instant homeowners insurance quote
                        <br />
                        <br />
                        Search for an address
                    </h4>
                }
            </div>
            <span />
        </div>

    function requestAssistance() {
        setModalState({ modalContent: 'requestAssistance' });
    }

    // State & Element | Re-Query Elements
    const reQueryMap__elements = <div className='requestHelp'>
        <h4>Still can't find what your looking for?</h4>
        <button className='primaryButton' onClick={requestAssistance}>Request Assistance</button>
    </div >

    function newQuote() {
        // document.getElementById('root').children[0].scrollIntoViewIfNeeded({ behavior: "smooth", block: "end", inline: "nearest" });
        const node = document.getElementById('root').children[0];

        scrollIntoView(node, {
            scrollMode: 'if-needed',
            block: 'start',
            inline: 'start',
        });


        setTimeout(function () {
            setSearchState(['initial']);
            document.getElementById('searchContainer').children[0].children[0].children[0].children[0].focus();
            document.getElementById('searchContainer').children[0].children[0].children[0].children[0].value = null;
        }, 500);
    }

    // State & Element | Re-Query Button
    const newQuoteButton = <div className='fadeIn'>
        <button onClick={newQuote}>New Quote</button>
    </div>


    return (
        <Fragment>
            {searchState == 'searching' ? <Loader /> : null}
            <div id="mapContainer">
                <div id="searchContainer" ref={mapDiv} className={(searchState == 'initial' || searchState == 'requote' || searchState[2] == 'requote') ? '' : 'remove'} />
                <div id='mapOverlay' className='fadeIn'>
                    {/* <InitialSearch />
                <CompleteSuccess />
                <CompleteError /> */}

                    {(searchState[3] == 'proposed') ? newQuoteButton : null} {/* New Quote Button */}
                    {((searchState == 'initial') || (searchState == 'requote' || searchState[2] == 'requote')) ? searchUI__elements : null} {/* Search Box */}

                    {(searchState == ['complete', 'error'] || searchState == ['requote'] || searchState[2] == 'requote') ? reQueryMap__elements : null}
                </div>

                {(searchState[2] == ['notConfirmed']) ? confirmLocation : null} {/* Confirm Buttons */}

                <link rel='stylesheet' href='https://js.arcgis.com/4.18/esri/themes/light/main.css' />
                <div className={`mapProcessingModal ${(searchState == 'searching') ? 'pointTwoOpacity' : ''}`}></div> {/* TODO What is this? */}
                <div className={`viewDivContainer ${(searchState[2] == 'notConfirmed') ? '' : 'disableMap'} ${(searchState == 'initial' || searchState == 'requote' || searchState[2] == 'requote') ? 'zeroOpacity' : ''}`}> {/* Map Container */}
                    <div className='viewDiv' ref={mapDiv}></div>
                </div>
                {/* <div className={`viewDiv ${viewDivOpacity ? '' : 'zeroOpacity'} ${!disableMap ? '' : 'disableMap'}`} ref={mapDiv}></div> */}
            </div >
        </Fragment>
    );
}

export default MapApp;