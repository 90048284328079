import React, { useContext, Fragment } from 'react';
import ReactDOM from 'react-dom';

import classes from './loader.module.css';


const Loader = (props) => {

    return (
        <React.Fragment >
            <div className={`${classes.ldsGridParent} fadeIn`}><div className={classes.ldsGrid}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
        </React.Fragment>
    );
}

export default Loader;