import React from 'react';
import classes from './option.module.css';
import './option.css';

// AJAX
// const axios = require('axios').default;

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


function Option(props) {

    const option = props.option;
    const editToggle = props.editToggle;

    // const [option, setOption] = useState(option);

    let optionFormatted;

    function typeSwitch(x) {
        switch (x[2]) {
            case 'currency':
                optionFormatted = formatter.format(x[1]);
                break;
            case 'percent':
                optionFormatted = x[1] + '% (' + formatter.format((x[1] * (x[3] * 0.01))) + ')';
                break;
            case 'text':
                optionFormatted = x[1];
                break;
            case 'int':
                optionFormatted = parseInt(x[1]);
                break;
            default:
                optionFormatted = x[2];
                break;
        }
        // console.log(optionFormatted);
    }
    typeSwitch(option);

    //
    function requestUpdatedQuote() {
        console.log('Request New Quote!')
    }

    let optionElement;

    // Check if this is option has options
    if (typeof option[4] === 'undefined') {
        optionElement = <input value={optionFormatted} className={props.editToggle ? 'enableEdit' : 'disableEdit'} inputMode="numeric" onChange={requestUpdatedQuote} />;
    }
    // If there are options
    else {
        let selectOptions = [];
        for (let selectOption of option[4]) {

            if (Array.isArray(selectOption)) {

                if (selectOption[2] === 'percent') {
                    selectOption[3] = option[3];
                }

                typeSwitch(selectOption);

                // console.log(selectOption);
                selectOptions.push(<option key={`${selectOption}__${optionFormatted}`} value={selectOption[1]}>{optionFormatted}</option>);

            } else {

                // Re-order variables because those that aren't percents..
                const selectOptionSameType = [null, selectOption, option[2], option[3]];

                // console.log("Select Options : " + selectOption);
                typeSwitch(selectOptionSameType);

                selectOptions.push(<option key={`${selectOption}__${optionFormatted}`} value={selectOption}>{optionFormatted}</option>);
            }
        }
        optionElement = <select value={option[1]} className={props.editToggle ? 'enableEdit' : 'disableEdit'} onChange={requestUpdatedQuote}>{selectOptions}</select>
    }
    return (
        <div className={classes.option}>
            {/* <div > */}
            <h3>{option[0]}</h3>
            {optionElement}
        </div >
    );
}

export default Option;
