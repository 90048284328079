import React, { useContext, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RequestInformation__MODAL from './requestInformation/RequestInformation';
import RequestAssistance__MODAL from './requestAssistance/RequestAssistance';
import QueryError__MODAL from './queryError/QueryError';
// import { faPaperclip, faIdCardAlt, faBell } from '@fortawesome/free-solid-svg-icons';
import ModalStateContext from '../../../store/ModalState';

import classes from './modal.module.css';


const Backdrop = props => {
    return (
        <div id='modalBackdrop' className={`${classes.backdrop} fadeIn`} onClick={props.onClick}></div>
    );
};

const ModalOverlay = (props) => {

    const { modalState__ctx } = useContext(ModalStateContext);
    const [modalState, setModalState] = modalState__ctx;

    const closeModal = () => {
        document.getElementById('modalOverlay').classList.remove('fadeIn');
        document.getElementById('modalBackdrop').classList.remove('fadeIn');
        setTimeout(function () {
            setModalState(null);
            // console.log("Modal closed!");
        }, 500);
    }

    return (
        <div id='modalOverlay' className={`${classes.modal} fadeIn`}>
            <div className={classes.close} onClick={props.onClose}>✘</div>
            {(modalState.modalContent == 'requestInformation') ? <RequestInformation__MODAL /> : null}
            {(modalState.modalContent == 'queryError') ? <QueryError__MODAL closeModal={closeModal} /> : null}
            {(modalState.modalContent == 'requestAssistance') ? <RequestAssistance__MODAL closeModal={closeModal} /> : null}
        </div>
    );
};

const Modal = (props) => {

    const { modalState__ctx } = useContext(ModalStateContext);
    const [modalState, setModalState] = modalState__ctx;

    const closeModal = () => {
        document.getElementById('modalOverlay').classList.remove('fadeIn');
        document.getElementById('modalBackdrop').classList.remove('fadeIn');
        setTimeout(function () {
            setModalState(null);
            console.log("Modal closed!");
        }, 500);
    }

    return (
        <React.Fragment >
            {modalState && ReactDOM.createPortal(<Backdrop onClick={closeModal} />, document.getElementById('backdrop-root'))}
            {modalState && ReactDOM.createPortal(<ModalOverlay onClose={closeModal} />, document.getElementById('backdrop-root'))}
        </React.Fragment>
    );
}

export default Modal;