import React, { Fragment, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faGlobeAmericas, faSearchLocation, faFileInvoice } from '@fortawesome/free-solid-svg-icons';

import norfolkAndDedham__logo from '../images/norfolkAndDedhamLogo.png';
import mapfre__logo from '../images/MAPFRELogo.png';
import mpiua__logo from '../images/MPIUALogo.png';
// import QuoteUI from '../components/stages/quoteDetails/QuoteDetails'
// import PropertySearch from '../components/stages/propertySearch/PropertySearch';
// import MassinsApiContext from '../store/MassinsApi';

import classes from './programInformation.module.css';


function ProgramInformation() {

    // Global Map Response Variable
    // const { macValue__ctx, searchState__ctx } = useContext(MassinsApiContext);
    // const [macValue, setMacValue] = macValue__ctx;

    // const [searchState, setSearchState] = searchState__ctx;

    return (
        <div className={classes.masterContainer}>
            <div className={classes.group}>
                <span className={classes.multipleComapniesTitle}>Quoting with Multiple Companies</span>
                <span>A single search is converted into multiple insurance quote requests, automatically. If there are more than one options available, the most competitive will be displayed.
                </span>

            </div>
            <div className={classes.logosContainer}>
                <img src={mapfre__logo} alt="MAPFRE_logo" />
                <img src={norfolkAndDedham__logo} alt="NorfolkAndDedham_logo" />
                <img src={mpiua__logo} alt="MPIUA_logo" />
            </div>
            <hr className={classes.hrBreak} />
            <span className={classes.bigTitle}>State of The Art</span>
            <div className={classes.group}>
                <FontAwesomeIcon icon={faStopwatch} className={classes.fontAwesomeIcon} />
                <h3>Average Quote In 5 Seconds</h3>
                <span>Limited by the speed of the internet and the device you are accessing this web-app with, quotes are near instant.</span>
            </div>
            <div className={classes.group}>
                <FontAwesomeIcon icon={faGlobeAmericas} className={classes.fontAwesomeIcon} />
                <h3>Leveraging Geographic Information Systems (GIS)</h3>
                <span>State of the art technologies and proprietary functions are used for quickly calcualting metrics needed to generate quotes.</span>
            </div>
            <div className={classes.group}>
                <FontAwesomeIcon icon={faSearchLocation} className={classes.fontAwesomeIcon} />
                <h3>No Questions Asked</h3>
                <span>Quotes are generated using know, calculated, and presumed data. Although quotes are accurate, they are accurate per the metrics quoted with which may not exacltly line up with your needs. Contact an agent for more information.</span>
            </div>

        </div>
    );
}

export default ProgramInformation;



