import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faIdCardAlt, faBell } from '@fortawesome/free-solid-svg-icons';

import ModalStateContext from '../../../../store/ModalState';
import MassinsApiContext from '../../../../store/MassinsApi';

import classes from './queryError.module.css';


function QueryError__MODAL(props) {

    // Global Map Response Variable
    const { macValue__ctx, searchState__ctx } = useContext(MassinsApiContext);
    const [macValue, setMacValue] = macValue__ctx;
    const [searchState, setSearchState] = searchState__ctx;

    const { modalState__ctx } = useContext(ModalStateContext);
    const [modalState, setModalState] = modalState__ctx;

    function requestAssistance() {
        setModalState({ modalContent: 'requestAssistance' });
    }


    return (
        <React.Fragment>
            {/* <h2 className={classes.title}>Error</h2> */}
            <div className={classes.detailsContainer}>
                <h2 className={classes.title}>{modalState ? modalState['details'] : null}</h2>
                <h4 className={classes.details}>Please try another address or request help using the button below.</h4>
            </div>
            <button className='primaryButton' onClick={props.closeModal}>Close</button>
            <button className='secondaryButton' onClick={requestAssistance}>Request Assistance</button>
        </React.Fragment>
    );
}

export default QueryError__MODAL;