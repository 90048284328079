import React, { useContext, useEffect } from 'react';
import MapApp from '../../map/MapApp';

import MassinsApiContext from "../../../store/MassinsApi";
import classes from './propertySearch.module.css';

function PropertySearch() {

    // Global Map Response Variable
    const { macValue__ctx, searchState__ctx } = useContext(MassinsApiContext);
    const [macValue, setMacValue] = macValue__ctx;
    const [searchState, setSearchState] = searchState__ctx;
    // const [confirmLocationButton__state, setConfirmLocation__state] = confirmLocationButton__ctx;

    const title = <h1 className={`${classes.title} fadeIn`}>Home Insurnace Quotes<br />Instantly</h1>;

    const parsedAddress = (searchState[2] == ['notConfirmed'] ?
        <div className={`${classes.parsedAddress__verification} fadeIn`} >
            {/* <h1 className={classes.parsedAddress}>{macValue["data"]["singleQuote__result"]["quoteRequest"]["propertyAddress"][0]}</h1> */}
            <h3> Is this the correct place?</h3>
            <h1 className={classes.parsedAddress}>{macValue["data"]["singleQuote__result"]["quoteRequest"]["propertyAddress"][0]}</h1>
        </div >
        : null);

    const addressNotFound = ((searchState[1] == 'retrurnedNoParcel') ? <div className={classes.parsedAddress__verification}>
        {/* <h1 className={classes.parsedAddress}>{macValue["data"]["singleQuote__result"]["quoteRequest"]["propertyAddress"][0]}</h1> */}
        <h3>The address you entered was not found.</h3>
    </div>
        : null);

    const addressNotResidential = ((searchState[1] == 'addressNotResidential') ?
        <div className={classes.parsedAddress__verification}>
            {/* <h1 className={classes.parsedAddress}>{macValue["data"]["singleQuote__result"]["quoteRequest"]["propertyAddress"][0]}</h1> */}
            <h3>Address not zoned as residential.</h3>
        </div>
        : null);

    const noQuotesAvailable = ((searchState[1] == 'noQuotesAvailable') ?
        <div className={classes.parsedAddress__verification}>
            {/* <h1 className={classes.parsedAddress}>{macValue["data"]["singleQuote__result"]["quoteRequest"]["propertyAddress"][0]}</h1> */}
            <h3>No quotes available for this address.</h3>
        </div>
        : null);

    return (
        <div className={classes.container}>
            <div className={classes.top}>
                {searchState[2] != 'notConfirmed' ? title : parsedAddress}
                {!addressNotFound ? null : addressNotFound}
            </div>
            <div className={classes.bottomContainer}>
                <div className={classes.bottom}>
                    <MapApp />
                </div>
            </div>
        </div>
    );
}

export default PropertySearch;